import { ServicesSoldTable } from '@/components/common/tables/services-sold-table/ServicesSoldTable';

const ServicesSold = () => {
  return (
    <div className="w-full">
      <ServicesSoldTable />
    </div>
  );
};

export default ServicesSold;
