import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const usePatchReservation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['patchReservation'],
    mutationFn: ({
      status,
      reservationId,
      data
    }: {
      status: string;
      reservationId: string;
      data?: any;
    }) => {
      return api.reservations.patchReservation({ status, reservationId }, data);
    },
    onError: (error) => {
      const errorMessage = error.response?.data.message;
      toast.error(t('toast.error', { errorMessage }), { duration: 5000 });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'reservation' ||
          query.queryKey[0] === 'reservations' 
      });
      toast.success(t('toast.reservationSuccess'));
    },
    retry: false
  });
};

export default usePatchReservation;
