import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StatusDetailModal from '../status-detail-modal/StatusDetailModal';
import { TCheckReservationsModal } from './checkReservationsModal.types';
const CheckReservationsModal = ({
  open,
  onOpenChange,
  onSubmit,
  reservations,
  closures
}: TCheckReservationsModal) => {
  const { t } = useTranslation();
  const [openStatusDetailM, setOpenStatusDetailM] = useState(false);

  const title = reservations.length
    ? t('alert.checkReservation.checkReservation')
    : t('alert.checkReservation.noReservations');

  const description = reservations.length
    ? t('alert.checkReservation.description')
    : t('alert.checkReservation.noReservationsDescription');

  /**
   * Si no hay bloqueos, se envía el update.
   * Si hay bloqueos, se abre el modal de detalle de bloqueos.
   */
  const handleOnClick = () => {
    if (
      Object.values(closures)[0].filter((item) => item.status === 'blocked')
        .length === 0
    ) {
      onSubmit({ closures });
      onOpenChange(false);
    } else {
      onOpenChange(false);
      setOpenStatusDetailM(true);
    }
  };

  return (
    <>
      <AlertDialog open={open} onOpenChange={onOpenChange}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription>{description}</AlertDialogDescription>
          </AlertDialogHeader>
          {reservations.length ? (
            <div className="divide-y">
              {reservations.map((reservation) => {
                const fullName = `${reservation.user.firstName} ${reservation.user.lastName}`;
                const serviceName = reservation.service.name;
                return (
                  <Link
                    to={`/reservations/${reservation.id}`}
                    key={reservation.id + reservation.user.id}
                  >
                    <div className="p-4 rounded hover:bg-gray-50 active:bg-gray-200">
                      <div className="flex gap-1">
                        <p className="font-bold">{t('common.service') + ':'}</p>
                        <p>{serviceName}</p>
                      </div>
                      <div className="flex gap-1">
                        <p className="font-bold">
                          {t('alert.checkReservation.name')}
                        </p>
                        <p>{fullName}</p>
                      </div>
                      <div className="flex gap-1">
                        <p className="font-bold">
                          {t('alert.checkReservation.saleId')}
                        </p>
                        <p>{reservation.sale.id}</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : undefined}
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={handleOnClick}>
              {t('common.next')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <StatusDetailModal
        open={openStatusDetailM}
        onOpenChange={setOpenStatusDetailM}
        onSubmit={onSubmit}
        closures={closures}
      />
    </>
  );
};

export default CheckReservationsModal;
