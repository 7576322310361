import api from '@/services/api';
import {
  keepPreviousData,
  skipToken,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { getMonth } from 'date-fns';
import { useEffect } from 'react';

const getServiceAvailabilityQueryOptions = ({
  // TODO: Ver por que no se puede poner una QueryFn que tenga un skiptoken
  serviceId,
  participants,
  month,
  year
}: {
  serviceId: string | undefined;
  participants: number | undefined;
  month: number;
  year: number;
}) => {
  return {
    queryKey: [
      'servicesAvailability',
      { serviceId, participants, month, year }
    ],
    staleTime: 10 * 10000
  };
};

const useServicesAvailability = ({
  serviceId,
  participants,
  month,
  year
}: {
  serviceId: string | undefined;
  participants: number | undefined;
  month: number;
  year: number;
}) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery({
      ...getServiceAvailabilityQueryOptions({
        serviceId,
        participants,
        month: month + 1,
        year
      }),
      queryFn:
        serviceId && participants
          ? () =>
              api.services.getAvailability({
                serviceId,
                participants,
                month: month + 1,
                year
              })
          : skipToken
    });

    queryClient.prefetchQuery({
      ...getServiceAvailabilityQueryOptions({
        serviceId,
        participants,
        month: month - 1,
        year
      }),
      queryFn:
        serviceId && participants
          ? () =>
              api.services.getAvailability({
                serviceId,
                participants,
                month: month - 1,
                year
              })
          : skipToken
    });
  }, [serviceId, participants, month, year]);

  return useQuery({
    ...getServiceAvailabilityQueryOptions({
      serviceId,
      participants,
      month,
      year
    }),
    queryFn:
      serviceId && participants
        ? () =>
            api.services.getAvailability({
              serviceId,
              participants,
              month,
              year
            })
        : skipToken,
    placeholderData: keepPreviousData
  });
};
export default useServicesAvailability;
