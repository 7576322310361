import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import NoData from '@/components/common/no-data/NoData';
import ChooseDateTimeStep from '@/components/section/new-booking/choose-date-time-step/ChooseDateTimeStep';
import ChooseServiceStep from '@/components/section/new-booking/choose-service-step/ChooseServiceStep';
import FinalMessage from '@/components/section/new-booking/final-message/FinalMessage';
import ParticipantsDataStep from '@/components/section/new-booking/participants-data-step/ParticipantsDataStep';
import Stepper from '@/components/section/new-booking/stepper/Stepper';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import { useRootStore } from '@/stores/root-store';
import { useEffect } from 'react';

const NewBookingPage = () => {
  const currentStep = useRootStore((store) => store.currentStep);
  const setStep = useRootStore((store) => store.setStep);
  const data = useRootStore((store) => store.stepServicesData);
  const cleanStepServicesData = useRootStore(
    (store) => store.cleanStepServicesData
  );
  // reset the step progressive bar
  useEffect(() => {
    setStep(1);
    cleanStepServicesData();
    return () => {
      setStep(1);
      cleanStepServicesData();
    };
  }, []);

  const organization = useGetOrganization();

  if (organization.isPending) {
    return (
      <div className="flex flex-col w-full gap-8">
        <Skeleton className="w-full h-8" />
        <div className="flex flex-col items-center w-full gap-6">
          <Skeleton className="w-11/12 h-8 " />
          <Skeleton className="w-11/12 h-8 " />
          <Skeleton className="w-11/12 h-8 " />
          <Skeleton className="w-11/12 h-8 " />
        </div>
      </div>
    );
  }
  if (!organization.data) return <NoData />;
  if (organization.isError) {
    return <ErrorMessage />;
  }
  return (
    <div className="w-full">
      <Stepper />
      <div className="container mt-8 mb-14">
        {currentStep === 1 && (
          <ChooseServiceStep organization={organization.data} />
        )}
        {currentStep === 2 && (
          <ChooseDateTimeStep organization={organization.data} />
        )}
        {currentStep === 3 && (
          <ParticipantsDataStep organization={organization.data} />
        )}
        {currentStep === 4 && <FinalMessage />}
      </div>
    </div>
  );
};

export default NewBookingPage;
