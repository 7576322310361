import { BlockServiceForm } from '@/components/forms/form-block-services/FormBlockServices';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useGetServicesById } from '@/hooks/queries/services-api/useGetOrganizationServices';
import useGetServiceActiveDates from '@/hooks/queries/services-api/useGetServiceActiveDates';
import usePostClousureCheck from '@/hooks/queries/services-api/usePostClosureCheck';
import usePostClouserServices from '@/hooks/queries/services-api/usePostClosureServices';
import { TCheckModalState } from '@/lib/utils';
import { format } from 'date-fns';
import { t } from 'i18next';
import { differenceWith, isEqual } from 'lodash';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import CheckReservationsModal from '../check-resevations-modal/checkReservationsModal';
import { BlockServiceModalT } from './block-service-modal.types';
import { Closures } from '../check-resevations-modal/checkReservationsModal.types';

export function BlockServiceModal({
  open,
  onOpenChange,
  serviceId
}: BlockServiceModalT) {
  const [checkModal, setCheckModal] = useState<TCheckModalState>({
    data: undefined,
    state: false
  });

  const {
    mutate: postClousureCheck,
    isPending: isPendingClosureCheck,
    data: closureCheck
  } = usePostClousureCheck(setCheckModal);

  const { mutate: postClouserServices } = usePostClouserServices();

  const { data: service } = useGetServicesById(serviceId);
  const { data: availableDates, isFetching } =
    useGetServiceActiveDates(serviceId);
  const [closures, setClosures] = useState<Closures>({});

  const isLoading = !!availableDates && !!service?.id && !isFetching;

  return (
    <>
      <Dialog
        open={checkModal.state ? false : open}
        onOpenChange={onOpenChange}
      >
        <DialogContent className="sm:max-w-[425px] pr-0 ">
          <DialogHeader>
            <DialogTitle>
              {t('common.blockService')}:
              <span className="font-normal"> {service?.name || '...'}</span>
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="max-h-[80vh] pr-4">
            {isLoading ? (
              <BlockServiceForm
                availableDates={availableDates}
                serviceId={service.id}
                isPending={isPendingClosureCheck}
                onSubmit={(values, form) => {
                  const originalValues =
                    availableDates[format(values.date, 'dd-MM-yyyy')];
                  const changedValues = differenceWith(
                    values.time,
                    originalValues,
                    isEqual
                  );

                  if (changedValues.length) {
                    const closures = {
                      [format(values.date, 'dd-MM-yyyy')]: changedValues
                    };
                    setClosures(closures);
                    serviceId
                      ? postClousureCheck({ closures, serviceId })
                      : postClousureCheck({ closures });
                  } else {
                    form.setError(
                      'time',
                      { type: 'focus', message: 'Realiza un cambio' },
                      { shouldFocus: true }
                    );
                  }
                }}
              />
            ) : (
              <div className="flex justify-center">
                <Loader className="w-4 h-4 mr-2 animate-spin" />
              </div>
            )}
          </ScrollArea>
        </DialogContent>
      </Dialog>
      {closureCheck !== undefined && (
        <CheckReservationsModal
          open={checkModal.state}
          onOpenChange={(value) => {
            onOpenChange(value);
            setCheckModal({ data: undefined, state: false });
          }}
          onSubmit={(data) =>
            postClouserServices({
              ...data,
              serviceId
            })
          }
          reservations={closureCheck}
          closures={closures}
        />
      )}
    </>
  );
}
