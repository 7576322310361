import { TBlockServiceSchedule } from '@/services/services';
import { SortingFn } from '@tanstack/react-table';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
function convertToTimestamp(dateStr: string): number {
  const dateObj = new Date(dateStr);
  const timestamp = dateObj.getTime();
  return timestamp;
}

export const sortTimeStampStartTimeFn: SortingFn<any> = (
  rowA,
  rowB
): number => {
  const statusA = rowA.original.startTime;
  const statusB = rowB.original?.startTime ? rowB.original.startTime : 0;

  if (!statusA) {
    return 0;
  }
  return statusA - statusB;
};

export const sortTimeISOCreatedAtFn: SortingFn<any> = (rowA, rowB): number => {
  const statusA = rowA.original?.createdAt
    ? new Date(rowA.original.createdAt).getTime()
    : 0;
  const statusB = rowB.original?.createdAt
    ? new Date(rowB.original.createdAt).getTime()
    : 0;
  if (!statusA) {
    return 0;
  }
  return statusA - statusB;
};

export const sortStartTimeFn: SortingFn<any> = (rowA, rowB): number => {
  const statusA = convertToTimestamp(rowA.original.startTime);
  const statusB = convertToTimestamp(
    rowB.original?.startTime ? rowB.original.startTime : 0
  );

  if (!statusA) {
    return 0;
  }

  return statusA - statusB;
};

export const sortTimeStampPaidAtFn: SortingFn<any> = (rowA, rowB): number => {
  const statusA = rowA.original.paidAt;
  const statusB = rowB.original.paidAt;

  return statusA - statusB;
};

export const sortUpdatedAtFn: SortingFn<any> = (rowA, rowB): number => {
  const statusA = convertToTimestamp(rowA.original.updatedAt);
  const statusB = convertToTimestamp(rowB.original.updatedAt);

  return statusA - statusB;
};

export type TCheckModalState =
  | {
      data: {
        closures: TBlockServiceSchedule;
        serviceId?: string;
      };
      state: true;
    }
  | {
      data: undefined;
      state: false;
    };

export const minAges = {
  paddle: 7,
  windsurf: 9,
  wingfoil: 10,
  userMinAge: 18
};

export const minHeight = 110;
export const maxHeight = 250;

export const maxHeightInputValidation = (value: number) =>
  value >= 0 && value <= maxHeight;

export const minWeight = 5;
export const maxWeight = 500;

export const maxWeightInputValidation = (value: number) =>
  value >= 0 && value <= maxWeight;

export const ALLOW_PAYMENT = {
  admin: 'admin',
  all: 'all'
};

export const CAN_UPDATE_RESERVATIONS = {
  admin: 'admin',
  all: 'all'
};

export enum DESCRIPTION_MAX_LENGTH {
  short = 255,
  medium = 511,
  long = 1023
}
