import ServiceCard from '@/components/common/cards/service-card/ServiceCard';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import ServiceDivision from '@/components/section/service-detail/serviceDivision/ServiceDivision';
import { Skeleton } from '@/components/ui/skeleton';
import useGetServiceDivisionByServiceId from '@/hooks/queries/service-division-api/useGetServiceDivisionByServiceId';
import useGetServiceById from '@/hooks/queries/services-api/useGetServiceById';
import usePatchService from '@/hooks/queries/services-api/usePatchService';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ServiceDetailPage = () => {
  const { t } = useTranslation();
  const { id: serviceId } = useParams();
  const {
    data: service,
    isError,
    isLoading,
    isRefetching
  } = useGetServiceById(serviceId);

  const {
    data: serviceDivision,
    isError: isErrorServiceDivision,
    isLoading: isLoadingServiceDivision,
    isRefetching: isRefetchingServiceDivision
  } = useGetServiceDivisionByServiceId(serviceId);

  const { mutate, isPending, isSuccess } = usePatchService({
    isSwitchService: true
  });

  if (isLoading) {
    return (
      <>
        <GoBack />
        <Skeleton className="w-full h-[80vh]" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <GoBack />
        <NoData />
        <ErrorMessage />
      </>
    );
  }

  if (!service) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }

  const onSwitchService = () => {
    const status = service.enabled;
    mutate({ payload: { enabled: !status }, serviceId: service.id });
  };

  return (
    <div className="mb-4">
      <GoBack />
      <div className="space-y-6">
        <p className="font-bold ">{t('serviceDetail.serviceInfo')}</p>
        <ServiceCard
          service={service}
          onSwitchService={onSwitchService}
          isPendingSwitch={isPending}
          disabledSwitch={!serviceDivision?.length}
        />
        <p className="font-bold ">{t('serviceDetail.serviceDivision')}</p>
        <ServiceDivision
          serviceDivision={serviceDivision}
          isLoading={isErrorServiceDivision}
          isError={isErrorServiceDivision}
        />
      </div>
    </div>
  );
};

export default ServiceDetailPage;
