import { BlockServiceForm } from '@/components/forms/form-block-services/FormBlockServices';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import useGetServiceActiveDates from '@/hooks/queries/services-api/useGetServiceActiveDates';
import usePostClousureCheck from '@/hooks/queries/services-api/usePostClosureCheck';
import usePostClouserServices from '@/hooks/queries/services-api/usePostClosureServices';
import { TCheckModalState } from '@/lib/utils';
import { format } from 'date-fns';
import { differenceWith, isEqual } from 'lodash';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckReservationsModal from '../check-resevations-modal/checkReservationsModal';
import { Closures } from '../check-resevations-modal/checkReservationsModal.types';

export function SchoolAvailabilityModal({
  open,
  onOpenChange
}: {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) {
  const { t } = useTranslation();

  const [checkModal, setCheckModal] = useState<TCheckModalState>({
    data: undefined,
    state: false
  });
  const {
    mutate: postClousureCheck,
    isPending: isPendingClosureCheck,
    data: closureCheck
  } = usePostClousureCheck(setCheckModal);

  const { mutate: postClouserServices } = usePostClouserServices();

  const { data: availableDates, isFetching } = useGetServiceActiveDates();
  const [closures, setClosures] = useState<Closures>({});

  const isLoading = !!availableDates && !isFetching;
  return (
    <>
      <Dialog
        open={checkModal.state ? false : open}
        onOpenChange={onOpenChange}
      >
        <DialogContent className="sm:max-w-[425px] pr-0 ">
          <DialogHeader>
            <DialogTitle>
              {t('settings.schoolAvailability')}:
              <span className="font-normal"> </span>
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="max-h-[80vh] pr-4">
            {isLoading ? (
              <BlockServiceForm
                availableDates={availableDates}
                isPending={isPendingClosureCheck}
                onSubmit={(values, form) => {
                  const originalValues =
                    availableDates[format(values.date, 'dd-MM-yyyy')];
                  const changedValues = differenceWith(
                    values.time,
                    originalValues,
                    isEqual
                  );

                  if (changedValues.length) {
                    const closures = {
                      [format(values.date, 'dd-MM-yyyy')]: changedValues
                    };
                    postClousureCheck({ closures });
                    setClosures(closures);
                  } else {
                    form.setError(
                      'time',
                      { type: 'focus', message: 'Realiza un cambio' },
                      { shouldFocus: true }
                    );
                  }
                }}
              />
            ) : (
              <div className="flex justify-center">
                <Loader className="w-4 h-4 mr-2 animate-spin" />
              </div>
            )}
          </ScrollArea>
        </DialogContent>
      </Dialog>
      {closureCheck !== undefined && (
        <CheckReservationsModal
          open={checkModal.state}
          onOpenChange={(value) => {
            onOpenChange(value);
            setCheckModal({ data: undefined, state: false });
          }}
          onSubmit={(data) => postClouserServices(data)}
          reservations={closureCheck}
          closures={closures}
        />
      )}
    </>
  );
}
