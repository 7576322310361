import { maxHeight, maxWeight, minHeight, minWeight } from '@/utils/utils';
import { subYears } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { z } from 'zod';
import { identificationValidation } from './validations';

export const allowPaymentSchema = z.enum(['admin', 'all']);
export const visibilitySchema = z.enum(['admin', 'all']);
export const canUpdateReservationsSchema = z.enum(['admin', 'all']);

export const rangeTimeSchema = z
  .string({ required_error: 'Se requiere una fecha.' })
  .regex(
    /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/,
    'Selecciona un rango horario valido'
  );

  export const phoneSchema = z
  .string({ required_error: 'Se requiere un número de teléfono.' })
  .transform((arg, ctx) => {
    if (!arg) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Ingresa un número de teléfono.'
      });
      return '';
    }

    const phone = parsePhoneNumberFromString(arg, {
      extract: false
    });

    // valid
    if (phone && phone.isValid()) {
      return phone.number;
    }

    // invalid
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Número inválido. Formato esperado: +Código de país seguido por el número.'
    });
  });

export const heightSchema = z.coerce
  .number({ required_error: 'Se requiere una altura.' })
  .min(minHeight, {
    message: `La altura debe ser de al menos ${minHeight} cm.`
  })
  .max(maxHeight, {
    message: `La altura máxima es de ${maxHeight} cm.`
  });

export const weightSchema = z.coerce
  .number({ required_error: 'Se requiere un peso.' })
  .min(minWeight, {
    message: `El peso debe ser de al menos ${minWeight} kg.`
  })
  .max(maxWeight, {
    message: `La peso máximo es de ${maxWeight} kg.`
  });

export const birthdateSchema = (age: number | undefined = 0) => {
  const minAge = age ? subYears(new Date(), age) : new Date();
  return z
    .date({
      required_error: 'Se requiere una fecha de nacimiento.'
    })
    .max(minAge, {
      message: age
        ? `La edad mínima es de ${age} años.`
        : 'Ingresa una fecha válida.'
    });
};

export const emailSchema = z
  .string({
    required_error: 'Se requiere una dirección de correo electrónico.'
  })
  .email({
    message: 'Ingresa una dirección de correo electrónico válida.'
  });

export const identificationSchema = z
  .object({
    number: z.string(),
    type: z.string()
  })
  .superRefine((obj, ctx) => {
    identificationValidation(obj, ctx);
    return false;
  });

export const firstNameSchema = z
  .string({ required_error: 'El nombre es requerido.' })
  .min(2, {
    message: 'El nombre de usuario debe tener al menos 2 caracteres.'
  });

export const lastNameSchema = z
  .string({ required_error: 'El apellido es requerido.' })
  .min(2, {
    message: 'El apellido de usuario debe tener al menos 2 caracteres.'
  });
