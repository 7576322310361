import { StoreApi } from 'zustand';
import { RootStore } from '@/stores/root-store.types';
import { StepService } from './step-service.types';

export const createStepServiceSlice = (
  set: StoreApi<RootStore>['setState'],
  get: StoreApi<RootStore>['getState'],
  ...rest: StoreApi<RootStore>[]
) => ({
  stepServicesData: undefined,
  loadStepServicesData: (data: StepService) =>
    set((state) => ({
      stepServicesData: (state.stepServicesData = {
        ...state.stepServicesData,
        ...data
      })
    })),
  cleanStepServicesData: () =>
    set({
      stepServicesData: undefined
    })
});
