import { StoreApi } from "zustand";
import { RootStore } from "@/stores/root-store.types";

export const createStepperSlice = (
  set: StoreApi<RootStore>["setState"],
  get: StoreApi<RootStore>["getState"],
  ...rest: StoreApi<RootStore>[]
) => ({
  currentStep: 1,
  addStep: () =>
    set((state) => ({
      currentStep: state.currentStep === 4 ? 4 : state.currentStep + 1,
    })),
  removeStep: () =>
    set((state) => ({
      currentStep: state.currentStep === 1 ? 1 : state.currentStep - 1,
    })),
  setStep: (step: number) =>
    set((state) => ({ currentStep: (state.currentStep = step) })),
});
