import { Progress } from "@/components/ui/progress";
import { useRootStore } from "@/stores/root-store";

type StepData = { porcentage: number };

const Stepper: React.FC= () => {
  const currentStep = useRootStore((state) => state.currentStep);

  const STEPS_DATA: { 1: StepData; 2: StepData; 3: StepData; 4: StepData } = {
    1: { porcentage: 25 },
    2: { porcentage: 50 },
    3: { porcentage: 75 },
    4: { porcentage: 100 },
  };

  const stepPorcentage =
    STEPS_DATA[currentStep as 1 | 2 | 3 | 4]?.porcentage || 25;

  return (
    <div>
      <div className="flex flex-row justify-around font-bold align-middle text-primary">
        {Object.keys(STEPS_DATA).map((step) => (
          <h3 key={step}>{step}</h3>
        ))}
      </div>

      <Progress value={stepPorcentage} />
    </div>
  );
};

export default Stepper;
