import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['uptade-user'],
    mutationFn: (data: { id: string; body: any }) =>
      api.user.patchUser(data.id, data.body),
    onSuccess: (data) => {
      queryClient.setQueryData(['users'], () => data);
      toast.success(t('toast.userSuccess'));
      navigate('/users');
    },
    onError: (error) => {
      const errorMessage = error?.message;
      toast.error(t('toast.error', { errorMessage }));
    }
  });
};
export default useUpdateUser;
