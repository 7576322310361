import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { TStatusDetailModal } from './status-detail-modal.types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';

const StatusSchema = () => {
  return z.object({
    statusDetail: z
      .record(
        z
          .string()
          .max(
            DESCRIPTION_MAX_LENGTH.medium,
            `La descripción no puede contener más de ${DESCRIPTION_MAX_LENGTH.medium} caracteres.`
          )
          .optional()
      )
      .optional()
  });
};

type TForm = ReturnType<typeof StatusSchema>;
type StatusValues = z.infer<TForm>;

const StatusDetailModal = ({
  open,
  onOpenChange,
  onSubmit,
  closures
}: TStatusDetailModal) => {
  const { t } = useTranslation();

  const slotsBlocked = Object.values(closures)[0]
    .filter((item) => item.status === 'blocked')
    .map((item) => item.slot);

  const form = useForm<StatusValues>({
    resolver: zodResolver(StatusSchema())
  });

  const handleSubmit = (data: StatusValues) => {
    const dateKey = Object.keys(closures)[0];
    const newClosures = Object.values(closures)[0];

    const payload = {
      closures: {
        [dateKey]: newClosures.map((item) => {
          if (item.status === 'blocked') {
            const statusDetail = data.statusDetail?.[item.slot];
            if (statusDetail) {
              return {
                ...item,
                statusDetail
              };
            }
          }
          return item;
        })
      }
    };

    onSubmit(payload);
    onOpenChange(false);
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('alert.statusDetail.title')}</AlertDialogTitle>
        </AlertDialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <>
              {slotsBlocked.map((slot) => (
                <FormField
                  key={slot}
                  control={form.control}
                  name={`statusDetail.${slot}`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{slot}</FormLabel>
                      <FormControl>
                        <Textarea className="resize-none" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              ))}
            </>

            <AlertDialogFooter className="mt-4">
              <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
              <Button type="submit">{t('common.confirm')}</Button>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default StatusDetailModal;
