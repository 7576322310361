import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import ServiceForm from '@/components/forms/form-service/ServiceForm';
import { ServicesFormValues } from '@/components/forms/form-service/ServiceForm.types';
import { Skeleton } from '@/components/ui/skeleton';
import useGetServiceById from '@/hooks/queries/services-api/useGetServiceById';
import usePatchService from '@/hooks/queries/services-api/usePatchService';
import useGetOrganizationSports from '@/hooks/queries/sports-api/useGetOrganizationSports';
import { getChangedValues } from '@/utils/forms';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const EditService = () => {
  const { t } = useTranslation();
  const { id: serviceId } = useParams();
  const { data: service, isError, isLoading } = useGetServiceById(serviceId);
  const {
    data: sport,
    isError: isErrorSport,
    isLoading: isLoadingSport
  } = useGetOrganizationSports();
  const { mutate, isPending } = usePatchService();

  if (isLoading || isLoadingSport) {
    return (
      <>
        <GoBack />
        <Skeleton className="w-full h-[50vh]" />
      </>
    );
  }

  if (isError || isErrorSport) {
    return (
      <>
        <GoBack />
        <NoData />
        <ErrorMessage />
      </>
    );
  }

  if (!service || !sport) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }

  const defaultValues = {
    name: service.name,
    description: service.description,
    price: service.price,
    reservationPct: service.reservationPct,
    minPreReservationTime: service.minPreReservationTime,
    sport: service.sport.id,
    allowPayment: service.allowPayment,
    visibility: service.visibility,
    canUpdateReservations: service.canUpdateReservations,
    minConsumers: service.minConsumers,
    maxConsumers: service.maxConsumers
  };

  const onSubmit = (data: ServicesFormValues) => {
    const onlyChangedData = getChangedValues<Partial<ServicesFormValues>>(
      defaultValues,
      data
    );
    mutate({ payload: onlyChangedData, serviceId: service.id });
  };

  return (
    <div className="mb-4">
      <GoBack />
      <div className="p-5 space-y-6 rounded-md bg-accent">
        <p className="font-bold text-md text-primary">
          {t('form.updateService')} - {service.name}
        </p>
        <ServiceForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          isPending={isPending}
          sport={sport}
        />
      </div>
    </div>
  );
};

export default EditService;
