import _ from 'lodash';

//funcion para comprar los valores de dos objetos. Devuelve un nuevo objeto solo con los valores que cambiaron. 

export const getChangedValues = <T extends object>(obj1: T, obj2: T): Partial<T> => {
  const result: Partial<T> = {};

  _.forOwn(obj2, (value, key) => {
    if (value === null || value === undefined) {
      return;
    }

    const originalValue = obj1[key as keyof T];

    if (_.isArray(value) && _.isArray(originalValue)) {
      // Compare arrays separately
      if (!_.isEqual(value, originalValue)) {
        result[key as keyof T] = value;
      }
    } else if (_.isObject(value) && _.isObject(originalValue)) {
      // Recursively compare objects
      const nestedDifferences = getChangedValues(
        originalValue as any,
        value as any
      );
      if (!_.isEmpty(nestedDifferences)) {
        result[key as keyof T] = nestedDifferences as any;
      }
    } else if (!_.isEqual(value, originalValue)) {
      result[key as keyof T] = value;
    }
  });

  return result;
};