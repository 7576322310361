import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';
import { Api } from './api';
import { TEmergencyContact, TIdentification, TUser } from './user';

export interface TConsumer {
  createdBy: string;
  updatedBy: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  active: boolean;
  id: string;
  user: TUser;
  firstName: string;
  lastName: string;
  birthdate: string; // ISO date string
  weight: number;
  height: number;
  identification: TIdentification;
  phone: string;
  address: string;
  location: string;
  postalCode: string;
  email: string;
  isOwnUser: boolean;
  emergencyName: string;
  emergencyPhone: string;
  emergencyContact: TEmergencyContact;
}

export type GetConsumersProps = {
  pagination: PaginationState;
  filtering: string;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
};

export type TGetConsumers = {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: TConsumer[];
};

export class ConsumerApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getConsumers(): Promise<TGetConsumers | undefined> {
    const response: ApiResponse<TGetConsumers> =
      await this.api.apisauce.get('consumer');
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getConsumerById(id: string): Promise<TConsumer | undefined> {
    const response: ApiResponse<TConsumer> = await this.api.apisauce.get(
      `consumer/${id}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async patchConsumer(id: string, body: TConsumer): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `user/admin/${id}`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results;
  }

  async getPaginatedConsumer({
    pagination,
    sorting,
    filtering,
    columnFilters
  }: GetConsumersProps): Promise<TGetConsumers | undefined> {
    //set paignation
    const page = pagination.pageIndex + 1;
    const limit = pagination.pageSize;

    // set sorting
    let sortBy = '';
    let sortDirection = '';

    //set columnFilters
    let columnFilterURI = '';

    columnFilters.forEach((filter) => {
      const valuesOfFilter = filter.value;
      let valueQueryString: string = '';

      if (Array.isArray(valuesOfFilter)) {
        valueQueryString = `&filter.${filter.id}=string.in.${valuesOfFilter.join(',')}`;
      } else {
        const dateRange = valuesOfFilter as {
          from: string;
          to: Date | undefined;
        };
        if (!dateRange.to) {
          const date = new Date(dateRange.from);
          // Set the time to the last hour of the day (23:00:00)
          date.setHours(23, 0, 0, 0);
          dateRange.to = date;
        }
        if (dateRange.from && dateRange.to) {
          valueQueryString = `&filter.createdAt=date.between.${format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss")},${format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss")}`;
        }
      }

      columnFilterURI += valueQueryString;
    });

    for (let i = 0; i < sorting.length; i++) {
      const id = sorting[i].id,
        direction = sorting[i].desc ? 'DESC' : 'ASC';
      sortBy += id;
      sortDirection += direction;

      if (i !== sorting.length - 1) {
        sortBy += ',';
        sortDirection += ',';
      }
    }

    let URI = `consumer?limit=${limit}&page=${page}`;
    if (filtering) {
      const filteringWithoutDot = filtering.replace('.', ''); //we remove [.] to avoid 500 error. Needs to be reviewd if we search for emails
      URI += `&filter.or.firstName=string.like.${filteringWithoutDot}&filter.or.lastName=string.like.${filteringWithoutDot}&filter.or.id=string.like.${filteringWithoutDot}&filter.or.phone=number.like.${filteringWithoutDot}`;
    }
    if (sorting.length) {
      URI += `&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }
    if (columnFilters.length) {
      URI += columnFilterURI;
    }
    const response: ApiResponse<TGetConsumers> =
      await this.api.apisauce.get(URI);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
