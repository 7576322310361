import { TOrganization } from '@/components/common/tables/services-sold-table/services-sold-tables.types';
import { ServicesFormValues } from '@/components/forms/form-service/ServiceForm.types';
import { GetServicesProps } from '@/hooks/queries/services-api/useGetPaginatedServices';
import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';
import { Api } from './api';
import { TimeSlot } from './equipments/equipments';
import { TSports } from './sports';

export type TBlockServiceSchedule = Record<string, TimeSlot[]>; 

export type Slot = {
  from: string; // "HH:MM"
  to: string; // "HH:MM"
  status: string;
  statusDetail?: string;
};

export type availableDateType = {
  date: string; // "DD-MM-YYYY"
  slots: Slot[];
};

export type TServices = {
  id: string;
  name: string;
  price: number;
  reservationPct: number;
  active: boolean;
  description: string;
  activeDays: any;
  activeMonths: any;
  isExtension: boolean;
  minPreReservationTime: number;
  enabled: true;
  sport: TSports;
  organization: TOrganization;
  allowPayment: 'all' | 'admin';
  visibility: 'all' | 'admin';
  canUpdateReservations: 'all' | 'admin';
  maxConsumers: number;
  minConsumers: number;
};

type TGetServices = {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: TServices[];
};

type TPostClosureResponse = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  active?: boolean;
  id?: string;
  organization?: {
    createdBy?: string;
    updatedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    active?: boolean;
    id?: string;
    name?: string;
    description?: string | null;
    email?: string;
    maxCancellationTime?: number;
    overdueCancellationPenalty?: number;
    activeDays?: string;
    activeMonths?: string;
    tz?: string;
  };
  isGlobal?: boolean;
  startTime?: number;
  endTime?: number;
  services?: {
    createdBy?: string;
    updatedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    active?: boolean;
    id?: string;
    name?: string;
    description?: string | null;
    price?: number;
    reservationPct?: number;
    activeDays?: string;
    activeMonths?: string;
    isExtension?: boolean;
  }[];
};

export type TCheckReservations = {
  id: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  sale: {
    id: string;
  };
  service: {
    name: string;
  };
}[];

export interface TPatchService extends ServicesFormValues {
  enabled: boolean;
}

export type TCategoryServices = {
  sport: {
    id: string;
    name: string;
  };
  services: TServices[];
};

export class ServicesApi {
  private readonly api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getAvailability({
    serviceId,
    participants,
    month,
    year
  }: {
    serviceId: string;
    participants: number;
    month: number;
    year: number;
  }): Promise<availableDateType[] | undefined> {
    const response: ApiResponse<availableDateType[]> = await this.api.apisauce.get(
      `service/schedule?service=${serviceId}&consumerQty=${participants}&month=${month}&year=${year}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getServices(): Promise<TServices[]> {
    const response: ApiResponse<TGetServices> =
      await this.api.apisauce.get('service');
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }
  async getServicesEnabled(): Promise<TServices[]> {
    const response: ApiResponse<TGetServices> = await this.api.apisauce.get(
      'service?filter.enabled=boolean.eq.true'
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async getServiceById(serviceId: string): Promise<TServices | undefined> {
    const response: ApiResponse<TServices> = await this.api.apisauce.get(
      `service/${serviceId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response?.data;
  }

  async patchService(
    payload: Partial<TPatchService>,
    serviceId: string
  ): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `service/${serviceId}`,
      payload
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async postService(payload: Partial<TPatchService>): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      'service',
      payload
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getServiceActiveDates(serviceId?: string): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      'service/active-dates',
      { service: serviceId }
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response?.data;
  }

  async postClosureServices(body: {
    closures: TBlockServiceSchedule;
    services?: string[];
  }): Promise<any> {
    const response: ApiResponse<TPostClosureResponse[]> =
      await this.api.apisauce.post('closure', body);
    if (!response.ok) {
      throw response.originalError;
    }
    return response?.data;
  }

  async postClosureCheckReservation(body: {
    closures: TBlockServiceSchedule;
    services?: string[];
  }): Promise<TCheckReservations | undefined> {
    const response: ApiResponse<TCheckReservations> =
      await this.api.apisauce.post('closure/check-reservations', body);
    if (!response.ok) {
      throw response.originalError;
    }
    return response?.data;
  }
  async deleteService(serviceId: string): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.delete(
      `service/${serviceId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getPaginatedServices({
    pagination,
    sorting,
    filtering,
    columnFilters
  }: GetServicesProps): Promise<TGetServices | undefined> {
    //set paignation
    const page = pagination.pageIndex + 1;
    const limit = pagination.pageSize;

    // set sorting
    let sortBy = '';
    let sortDirection = '';

    //set columnFilters
    let columnFilterURI = '';

    columnFilters.forEach((filter) => {
      const valuesOfFilter = filter.value;
      let valueQueryString: string = '';

      if (Array.isArray(valuesOfFilter)) {
        valueQueryString = `&filter.${filter.id}=string.in.${valuesOfFilter.join(',')}`;
      } else {
        const dateRange = valuesOfFilter as {
          from: string;
          to: Date | undefined;
        };
        if (!dateRange.to) {
          const date = new Date(dateRange.from);
          // Set the time to the last hour of the day (23:00:00)
          date.setHours(23, 0, 0, 0);
          dateRange.to = date;
        }
        if (dateRange.from && dateRange.to) {
          valueQueryString = `&filter.createdAt=date.between.${format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss")},${format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss")}`;
        }
      }

      columnFilterURI += valueQueryString;
    });

    for (let i = 0; i < sorting.length; i++) {
      const id = sorting[i].id,
        direction = sorting[i].desc ? 'DESC' : 'ASC';
      sortBy += id;
      sortDirection += direction;

      if (i !== sorting.length - 1) {
        sortBy += ',';
        sortDirection += ',';
      }
    }

    let URI = `service?limit=${limit}&page=${page}`;
    if (filtering) {
      const filteringWithoutDot = filtering.replace('.', ''); //we remove [.] to avoid 500 error. Needs to be reviewd if we search for emails
      URI += `&filter.or.name=string.like.${filteringWithoutDot}&filter.or.sport.name=string.like.${filteringWithoutDot}&filter.or.description=string.like.${filteringWithoutDot}&filter.or.id=string.like.${filteringWithoutDot}`;
    }
    if (sorting.length) {
      URI += `&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }
    if (columnFilters.length) {
      URI += columnFilterURI;
    }
    const response: ApiResponse<TGetServices> =
      await this.api.apisauce.get(URI);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async getOrganizationCategoriesWithServices(
    organizationId: string
  ): Promise<TCategoryServices[]> {
    const response: ApiResponse<TCategoryServices[]> =
      await this.api.apisauce.get(
        `service/sport?organization=${organizationId}`
      );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data || [];
  }
}
