import { maxHeight, maxWeight, minHeight, minWeight } from '@/utils/utils';
import { isBefore, subYears } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { z } from 'zod';

export const phoneValidation = (
  value: string,
  path: [string],
  ctx: z.RefinementCtx
) => {
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Ingresa un número de teléfono.',
      path
    });
    return '';
  }

  const phone = parsePhoneNumberFromString(value, {
    extract: false
  });

  // valid
  if (phone && phone.isValid()) {
    return phone.number;
  }

  // invalid
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: '+Código de país seguido por el número',
    path
  });
};

export const birthdateValidation = (
  minAge: number,
  value: Date | undefined,
  path: [string],
  ctx: z.RefinementCtx
) => {
  // invalid
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Ingresa una fecha de nacimiento.',
      path
    });
    return z.NEVER;
  }
  if (isBefore(subYears(new Date(), minAge), value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `La edad mínima es de ${minAge} años.`,
      path
    });
    return z.NEVER;
  }

  // valid
  return value;
};

export const heightValidation = (
  value: number | undefined,
  path: [string],
  ctx: z.RefinementCtx
) => {
  // invalid
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Ingresa una altura.',
      path
    });
    return z.NEVER;
  }
  if (value < minHeight) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `La altura debe ser de al menos ${minHeight} cm.`,
      path
    });
    return z.NEVER;
  }
  if (value > maxHeight) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `La altura máxima es de ${maxHeight} cm.`,
      path
    });
    return z.NEVER;
  }

  // valid
  return value;
};

export const weightValidation = (
  value: number | undefined,
  path: [string],
  ctx: z.RefinementCtx
) => {
  // invalid
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Ingresa un peso.',
      path
    });
    return z.NEVER;
  }
  if (value < minWeight) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `El peso debe ser de al menos ${minWeight} kg.`,
      path
    });
    return z.NEVER;
  }
  if (value > maxWeight) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `La peso máximo es de ${maxWeight} kg.`,
      path
    });
    return z.NEVER;
  }
  // valid
  return value;
};

export function identificationValidation(
  value: { type: string; number: string } | undefined,
  ctx: z.RefinementCtx,
  path?: [string]
) {
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['identification', 'type'],
      message: 'Debes ingresar un documento.'
    });
    return z.NEVER;
  }

  if (!value.type) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: path ? [...path, 'type'] : ['identification', 'type'],
      message: 'Selecciona un tipo de documento.'
    });
  }

  if (!value.number) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: path ? [...path, 'number'] : ['identification', 'number'],
      message: 'Ingresa un documento.'
    });
    return z.NEVER;
  }

  return value;
}

export const nameValidation = (
  value: string | undefined,
  path: [string],
  ctx: z.RefinementCtx
) => {
  // valid
  if (value && value.length >= 2) {
    return value;
  }

  // invalid
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: 'Debe tener como mínimo 2 caracteres.',
    path
  });
  return z.NEVER;
};
